import React from 'react';
import { Container } from 'reactstrap';

import { Footer, Header, Layout } from '@components';
import { Atlantis, Manifest, PaintJob, Reboot, Submerged, WiscoKid } from '@components/BlogEntries';

import './index.scss';

const BlogPage = () => (
  <Layout>
    <nav aria-label="Main navigation" className="main-nav sticky-top" role="navigation">
      <Header displayCheatCodes={false} />
    </nav>
    <main role="main">
      <Container className="blog px-0" fluid>
        <Atlantis includeSeo={false} />
        <Submerged includeSeo={false} />
        <PaintJob includeSeo={false} />
        <WiscoKid includeSeo={false} />
        <Manifest includeSeo={false} />
        <Reboot includeSeo={false} />
      </Container>
    </main>
    <footer role="contentinfo">
      <Footer />
    </footer>
  </Layout>
);

export default BlogPage;

import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import { Card } from 'reactstrap';

import { Doublet, SEO } from '@components';
import { BlogEntryProps } from '@types';

const Submerged = ({ includeSeo }: BlogEntryProps) => (
  <StaticQuery
    query={graphql`
      query {
        coverBack: file(name: {eq: "Submerged-Back"}) {
          publicURL
        }
        coverFront: file(name: {eq: "Submerged-Front"}) {
          publicURL
        }
      }
    `}
    render={({ coverBack, coverFront }) => (
      <>
        {includeSeo && (
          <SEO
            description={'"Submerged" is out NOW!'}
            image={coverFront.publicURL}
            slug="submerged"
            title="Submerged - troyleft."
          />
        )}
        <Card className="px-0">
          <h2 className="mb-0 mt-3 text-center">Submerged - troyleft.</h2>
          <span className="mb-3 text-center">19 May 2021</span>
          <div className="body mx-auto px-2 px-sm-0">
            <Doublet
              left={{
                alt: 'The front album cover of Submerged featuring the late Keaton blowing rings underwater, everything has a blue filter.',
                src: coverFront.publicURL,
              }}
              right={{
                alt: 'The back album cover of Submerged listing the title of each track. Everything has a blue filter.',
                src: coverBack.publicURL,
              }}
            />
            <p>
              <span className="d-table spotify-iframe-container mb-0">
                <iframe
                  height="80"
                  src="https://open.spotify.com/embed/album/5cgjtGxfk9SRrfhtxjbsdz"
                  title="Spotify Embed"
                  width="300"
                />
              </span>
            </p>
            <p>
              <em>Submerged</em>, the latest project by troyleft. has just dropped. This 13 track project chronicles the ups and downs that come with navigating through recovery and shines a spotlight on real stories from his peers within the recovery community. During the mixing process, I had the chance to meet a few of his peers featured on this project at a Sober Living house in Milwaukee, WI and learn quite a bit about sober living and the recovery community. That experience definitely informed how I approached the mixing process; the stories you hear on this record gripped me and I did my best to showcase them.
            </p>
            <p>
              For me, &quot;Narcan&quot; has consistently been the track that has hit me the most. The beat is 🔥🔥🔥 and the lyrics are real and inquisitive. To me, it sounds like he&apos;s asking society at large what it is doing to address this kind of situation. This record has made me consider addiction and how society responds to it.
            </p>
            <hr />
            <p>
              If you or someone you know is struggling with substance use and/or mental health issues, I encourage you to <a href="https://findtreatment.gov/">reach out to SAMHSA via their website</a> or on their hotline at <a href="tel:18006624357">1-800-662-HELP (4357)</a>.
            </p>
            <hr />
          </div>
        </Card>
      </>
    )}
  />
);

export default Submerged;

import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import { Card } from 'reactstrap';

import { SEO } from '@components';
import { BlogEntryProps } from '@types';

const Reboot = ({ includeSeo }: BlogEntryProps) => (
  <StaticQuery
    query={graphql`
      query {
        file(name: {eq: "DiamondPiercing-levels"}) {
          publicURL
        }
      }
    `}
    render={({ file: { publicURL } }) => (
      <>
        {includeSeo && (
          <SEO
            description={'I had been recording and producing for over half a decade, but I had never once before worked with someone remotely until I got an email from out of the blue.'}
            image={publicURL}
            slug="reboot"
            title="Remote work is for audio engineers too"
          />
        )}
        <Card className="px-0">
          <div className="d-block mb-2 mx-auto"></div>
          <h2 className="mb-0 mt-3 text-center">Re: boot</h2>
          <span className="mb-3 text-center">23 September 2018</span>
          <div className="body mx-auto px-2 px-sm-0">
            <h3>Lego bricks</h3>
            <p>
              Out of the blue, my brother said he hooked me up with a recording gig by mentioning me to one of his co-workers named Jake. Sure enough, Jake emailed me, explaining his vision for his debut project and asked me to help him record it. At this point, I had been recording and producing for people a little over half a decade by this point, but I had never once before worked with someone remotely. He sent over a demo of himself singing and playing guitar, and that was all that I needed. After listening to it a few times, I realized this was clearly a guitar-driven project, something between hard-rock and alt-rock. I got so excited, and immediately starting charting everything out and laying down tracks. Each song ended up with at least six electric guitar tracks, for a really aggressive, thick sound. I&apos;d say it took maybe an hour per part, and we ended up doing six songs, so you do the math. With guitar in place, I moved to laying down the rhythm. Since there wasn&apos;t a budget to track drums in a studio, I resorted to programming drums; it forced me to imagine what the part should sound like before laying it down, but I would never try to program drums for this kind of music, ever again. The bass followed the chords for the most part, although there were some moments where it had a counter groove; those parts were recorded straight into the interface, DI-style, on five-string active bass.
            </p>
            <p>
              Those three sets of instruments made up the bulk of the music, but sometimes the song required a couple extra instruments. Acoustic guitar was, by far, the hardest instrument to get right due to apartment living. The electric instruments are nice because you can just plug everything in, no mics required, but you can&apos;t really do that with an acoustic guitar. As tracks got parts added, they got uploaded to the cloud so we could both mull over the arrangements. Eventually, Jake asked me to write a guitar solo for each track. Over the years, I&apos;ve guest solo-ed on a bunch of projects and as a result, I have my own process: jamming. I just record myself noodling over the track for a while and splice melodies out of different takes together to create the narrative. With the narrative nailed down, I re-recorded the entire spliced solo and called it a day.
            </p>
            <h3>Back home</h3>
            <p>
              After finalizing the arrangements, we moved on to cutting vocals. We booked a session at good ol&apos; Maple Tree Studio where we would meet face to face for the first time. I showed up an hour early for setup, and threw up an AKG C414, a Neumann TLM-103, and a Violet Amethyst as first guesses for the mic. When Jake arrived, as part of his warm up, I had him sing through each mic. We quickly moved forward with the Violet Amethyst as his vocal mic. The final vocal chain used was the Violet Amethyst into a Toft ATB16 into a DigiDesign 003, with a BLA mod, and finally into ProTools. We took the same approach for each song; he&apos;d sing a couple complete run-throughs and we&apos;d go back, section by section. By the end of the day, we had cut five out of six tracks and were feeling pretty good. Since we didn&apos;t get through all six songs, we decided to book another session in a few weeks.
            </p>
            <p>
              Back at Maple Tree, the first item on the agenda was cutting the vocal for “Run or Stay” since we hadn&apos;t tracked it yet. Jake took me by surprise during the outro of this song with this gnarly, guttural scream which I think sounds amazing. We did a couple more takes of the scream and took a break to rest his voice. From there, we went back and re-recorded around 40% of the vocals on the rest of the project. With another intense eight hour session behind us, we both finally felt ready to start the editing process.
            </p>
            <div className="mx-auto pb-4 text-center">
              <img
                alt={'The vocal channel-strips used in "Diamond Piercing"'}
                className="d-block mb-0 mx-auto"
                src={publicURL}
              />
              <small>The vocal channel-strips used in &quot;Diamond Piercing&quot;</small>
            </div>
            <h3>The glue</h3>
            <p>
              With all the tracks now done, I returned home, backed everything up, and started comping. If you don&apos;t know, comping (compositing) is the process of stitching together various parts of a track to form a more polished performance. Truthfully, I use a lot of processing power come mix time, and these mixes were no different. Each vocal has stacked compressors to better control the volume and do proper gain-staging. Listening back, I kept wanting a more edgy bit on his voice. For this, I used a twist on &quot;parallel compression.&quot; To achieve what I want, I duplicate the main vocal and add heavy compression, light distortion, and mix it in at a lower volume. The same process was used on both the electric guitar, bass guitar, and even the drums. For my time-based effects, I routed separate sends for both delays and reverb, saved it as a preset, and blended to taste on each song. The amount of reverb changed dramatically on &quot;One More Time&quot; where Jake wanted to go full-on arena rock, but the rest of songs have a pretty mild amount of reverb.
            </p>
            <p>
              After about a month of sending mixes back and forth, Jake was pretty happy with the results, which meant it was time for mastering. Each two-track got an instance of iZotope&apos;s Ozone and started out with an EQ, compressor, and maximizer. A couple of revisions later, and some much needed car tests, and we landed on final masters. All in all, the process took almost a year, a fact I blame on the roughly 300 miles of distance between us. At times, the process was challenging and I picked up some good experience working in a &quot;modern-rock&quot; style. If you&apos;re curious what the final project sounds like, I&apos;ve linked the single (my favorite track) below.
            </p>
            <p>
              <span className="d-table iframe-container mb-0">
                <iframe
                  allowFullScreen
                  className="mb-0"
                  height="315"
                  src="https://www.youtube.com/embed/lkNlc441Z34?rel=0&cc_load_policy=1&cc_lang_pref=en"
                  title="Diamond Piercing Youtube Embed"
                  width="560"
                />
              </span>
            </p>
            <hr />
          </div>
        </Card>
      </>
    )}
  />
);

export default Reboot;
